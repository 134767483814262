import { useContext } from "react";
import { UserContext } from "../../auth/Verified";
import { Link } from "react-router-dom";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import Header from "../../components/layout/Header";
import LineChart from "../../components/charts/LineChart";
import PieChart from "../../components/charts/PieChart";
import BarChart from "../../components/charts/BarChart";
import StatBox from "../../components/StatBox";
import useClaims from '../../auth/claims';
import useMediaQuery from "@mui/material/useMediaQuery";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const user = useContext(UserContext);

  const handleSelectedBusinessChanged = () => {
    console.log('User selected a different business');
  }

  return  (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex"  alignItems="center">
        <Header title={user.selectedBusiness?.businessName ?? user.firstName} subtitle="Welcome to your dashboard" />

        {/* <Box>
          <Select
            variant="filled"
            name="selectedBusiness"
            value={user.selectedBusiness?.id ?? 0}
            onChange={handleSelectedBusinessChanged}
          >
            {user.businesses?.map((business) => (
              <MenuItem key={business.id} value={business.id}>
                {business.businessName}
              </MenuItem>
            ))}
          </Select>
        </Box> */}
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >

        { !isNonMobile && (
          <Box gridColumn="span 12"
          borderRadius="20px"
          backgroundColor={colors.primary[400]}
          display="flex"
          flexDirection="column"
          alignItems="left"
          justifyContent="center"
          padding="30px">
            <Typography variant="h3" sx={{ color: colors.grey[100] }}>
              Document Sharing
            </Typography>
            <Box marginTop="10px">
              <a style={{color: colors.greenAccent[500], fontSize: "20px"}} href={user.sharePointLink} target="_blank">{user.firstName}'s SharePoint</a>
            </Box>
          </Box>
        )}

        {/* ROW 1 */}
        <Box
          gridColumn={isNonMobile ? "span 4" : "span 12"}
          borderRadius="20px"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="Bank Balance"
            resourceUrl="/api/financials/CashBalance"
            icon={
              <AttachMoneyIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn={isNonMobile ? "span 4" : "span 12"}
          borderRadius="20px"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="Gross Profit"
            resourceUrl="/api/financials/GrossProfit"
            icon={
              <PointOfSaleIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn={isNonMobile ? "span 4" : "span 12"}
          borderRadius="20px"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="Net Profit"
            resourceUrl="/api/financials/NetProfit"
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}

        <Box
          gridColumn="span 12"
          gridRow="span 2"
          borderRadius="20px"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            height="15%"
          >
            <Typography variant="h3" sx={{ color: colors.greenAccent[500] }}>
              Financials
            </Typography>
            <Typography
              variant="h5"
              fontWeight="600"
              color={colors.greenAccent[500]}
            >
              <Link sx={{ color: colors.greenAccent[500]}} to="/line">Details</Link>
            </Typography>
          </Box>
          <Box height="85%">
            <LineChart resourceUrl='/api/charts/line-chart?metrics=profit,revenue,expenses' isDashboard={true} />
          </Box>
        </Box>

        {/* ROW 3 */}

        <Box
          gridColumn={isNonMobile ? "span 8" : "span 12"}
          gridRow="span 4"
          borderRadius="20px"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Box 
            display="flex"
            justifyContent="space-between"
            height="15%"
          >
            <Typography variant="h3" sx={{ color: colors.greenAccent[500] }}>
              Expense Detail
            </Typography>
            <Typography
              variant="h5"
              fontWeight="600"
            >
              <Link sx={{ color: colors.greenAccent[500]}} to="/pie">Details</Link>
            </Typography>
          </Box>
          <Box height="85%">
            <PieChart resourceUrl='/api/charts/pie-chart?metric=expenses' isCompact={true} />
          </Box>
        </Box>
        <Box
          gridColumn={isNonMobile ? "span 4" : "span 12"}
          gridRow="span 2"
          borderRadius="20px"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="Estimated Tax Liability"
            resourceUrl="/api/financials/EstimatedTaxLiability"
            icon={
              <LocalHospitalIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Sales Quantity
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Dashboard;