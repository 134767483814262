import axios from 'axios';
import { useQuery } from 'react-query';

const claimsKeys = {
  claim: ['claims']
}

let bffBaseUrl = (process.env.NODE_ENV == 'production')
  ? ''
  : 'https://localhost:5002';
const config = {
  headers: {
    'X-CSRF': '1'
  }
}

const fetchClaims = async () => {
  return axios.get(`${bffBaseUrl}/bff/user`, config)
    .then((res) => res.data);
}


function useClaims() {
  return useQuery(
    claimsKeys.claim,
    async () => fetchClaims(),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: false
    }
  )
}

export { useClaims as default }