import { useEffect, useState } from 'react';
import { Box, Button, Typography, TextField, useTheme, Select, InputLabel, FormControl, MenuItem } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Header from "../layout/Header";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import clientInfoApi from '../../api/ClientInfo';

const BusinessInfoForm = (props) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [done, setDone] = useState(false);
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
      (async () => {
          const businesses = await clientInfoApi.getBusinesses();
          setDone(true);
          setInitialValues(businesses[0] ?? {});
      })();
    }, []);

    const handleFormSubmit = async (values) => {
      console.log(values);
      setDone(false);
      await clientInfoApi.updateBusiness(JSON.stringify(values));
      props.setStep(props.currentStep + 1);
    };

    const schema = GetValidationSchema();

    return !done ?
    (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          <Header title="One second..." subtitle="" />
      </Box>
    ) : (
      <Box m="20px">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={schema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              marginTop="30px"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Box sx={{ gridColumn: "span 4" }}>
                <Typography 
                    variant="h3" 
                    color={colors.grey[100]} 
                    fontWeight="bold" 
                    sx={{ mb: "5px" }}
                >
                    Primary Business Information
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[400]}>
                    If you have multiple businesses, you can add them later.
                </Typography>
              </Box>
              <input type="hidden" name="id" value={values.id} />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Business Name *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Doing Business As"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.doingBusinessAs}
                name="doingBusinessAs"
                error={!!touched.doingBusinessAs && !!errors.doingBusinessAs}
                helperText={touched.doingBusinessAs && errors.doingBusinessAs}
                sx={{ gridColumn: "span 2" }}
              />
              <FormControl fullWidth sx={{ gridColumn: "span 2" }}>
                <InputLabel id="tax-structure-label">Tax Structure</InputLabel>
                <Select
                  labelId="tax-structure-label"
                  variant="filled"
                  name="taxStructure"
                  value={values.taxStructure}
                  label="Tax Structure"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.taxStructure && !!errors.taxStructure}
                  helperText={touched.taxStructure && errors.taxStructure}
                >
                  <MenuItem value={1}>Partnership</MenuItem>
                  <MenuItem value={2}>Sole Proprietorship</MenuItem>
                  <MenuItem value={3}>S-Corp</MenuItem>
                  <MenuItem value={4}>C-Corp</MenuItem>
                  <MenuItem value={5}>Trust</MenuItem>
                  <MenuItem value={6}>Non-Profit</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="EIN *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ein}
                name="ein"
                error={!!touched.ein && !!errors.ein}
                helperText={touched.ein && errors.ein}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Principal Address 1 *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.principalAddress1}
                name="principalAddress1"
                error={!!touched.principalAddress1 && !!errors.principalAddress1}
                helperText={touched.principalAddress1 && errors.principalAddress1}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Principal Address 2"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.principalAddress2}
                name="principalAddress2"
                error={!!touched.principalAddress2 && !!errors.principalAddress2}
                helperText={touched.principalAddress2 && errors.principalAddress2}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="City *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.city}
                name="city"
                error={!!touched.city && !!errors.city}
                helperText={touched.city && errors.city}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="State *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.state}
                name="state"
                error={!!touched.state && !!errors.state}
                helperText={touched.state && errors.state}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Zip Code *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.zip}
                name="zip"
                error={!!touched.zip && !!errors.zip}
                helperText={touched.zip && errors.zip}
                sx={{ gridColumn: "span 2" }}
              />
              <DatePicker
                value={values.entityReportDueDate}
                name="entityReportDueDate"
                fullWidth
                variant="filled"
                label="Entity Report Due Date (optional)"
                error={!!touched.entityReportDueDate && !!errors.entityReportDueDate}
                helperText={touched.entityReportDueDate && errors.entityReportDueDate}
                onBlur={handleBlur}
                onChange={value => {
                  const date = new Date(value).toLocaleDateString();
                  setFieldValue('entityReportDueDate', date, true);
                }}
                sx={{ gridColumn: "span 2" }}
              />
              <DatePicker
                value={values.dateOfIncorporation}
                name="dateOfIncorporation"
                fullWidth
                variant="filled"
                label="Date of Incorporation *"
                error={!!touched.dateOfIncorporation && !!errors.dateOfIncorporation}
                helperText={touched.dateOfIncorporation && errors.dateOfIncorporation}
                onBlur={handleBlur}
                onChange={value => {
                  const date = new Date(value).toLocaleDateString();
                  setFieldValue('dateOfIncorporation', date, true);
                }}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" mt="20px">
                <Button onClick={() => {props.setStep(props.currentStep - 1)}} color="secondary" variant="contained">
                    Back
                </Button>
                <Button type="submit" color="secondary" variant="contained">
                    Continue
                </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
    )        
}

const dateRegExp = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-](19|20)\d\d$/;
const einRegExp = /^\d{2}-?\d{7}$/;

const GetValidationSchema = () => {
  return yup.object().shape({
    name: yup.string().required("Required"),
    taxStructure: yup.number().required("Required").min(1, 'Nice Try').max(6, 'Nice Try'),
    ein: yup.string().required("Required")
      .matches(einRegExp, "EIN should be in the format XX-XXXXXXX"),
    principalAddress1: yup.string().required("Required"),
    city: yup.string().required("Required"),
    state: yup.string().required("Required"),
    zip: yup.string().required("Required").min(5, 'Zip code must be 5 digits').max(5, 'Zip code must be 5 digits'),
    entityReportDueDate: yup.string(),
    dateOfIncorporation: yup.string().required("Required")
  });
}

export default BusinessInfoForm;