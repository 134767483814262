import { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import axios from "axios";

const StatBox = ({ title, icon, resourceUrl }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let apiBaseUrl = (process.env.NODE_ENV == 'production')
    ? ''
    : 'https://localhost:5002';

  const [data, setData] = useState({value: 'No Data', asOfDate: ''});

  useEffect(() => {
    const headers = {
      'x-csrf': 1
    };
    axios.get(`${apiBaseUrl}${resourceUrl}`, { headers })
    .then(response => {
      console.log(response.data);
      if (!response.data.data)
        response.data = 'No Data Found'
      setData(response.data.data);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);

  return (
    <Box width="100%" m="0 30px">
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography
            variant="h2"
            fontWeight="bold"
            sx={{ color: colors.grey[100] }}
          >
            {data?.value?.toLocaleString('en-US', { style: 'currency', currency: 'USD'}) ?? 'No data'}
          </Typography>
        </Box>
        <Box>
          {icon}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" mt="2px">
        <Box>
          <Typography variant="h3" sx={{ color: colors.greenAccent[500] }}>
            {title}
          </Typography>
        </Box>
        <Box>
          <Typography variant="p" sx={{ color: colors.grey[300] }}>
            As of {new Date(data?.asOfDate).toLocaleDateString('en-US', {timeZone: 'UTC'})}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default StatBox;