import { useEffect, useState } from 'react';
import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../../theme";
import { Box, useTheme } from "@mui/material";
import { mockPieData as data } from "../../data/mockData";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";

const PieChart = ({ resourceUrl, isCompact = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  let apiBaseUrl = (process.env.NODE_ENV == 'production')
    ? ''
    : 'https://localhost:5002';

  const [pieData, setPieData] = useState('Loading...');

  useEffect(() => {
    const headers = {
      'x-csrf': 1
    };
    axios.get(`${apiBaseUrl}${resourceUrl}`, { headers })
    .then(response => {
      console.log(response.data);
      setPieData(!response.data.data ? undefined : response.data.data);
    })
    .catch(error => {
      setPieData(undefined);
      console.error('Error fetching data:', error);
    });
  }, []);

  if (pieData === 'Loading...') {
    return (
      <Box height="250px" m="20px 0 0 0" p="0 30px">
        Loading...
      </Box>
    )
  }
  else if (!pieData) {
    return (
      <Box height="250px" m="20px 0 0 0" p="0 30px">
        No Data
      </Box>
    )
  }
  else {
    return (
      <Box display="flex" flexDirection="column" height="100%" width="100%">
        <Box>
          As of {pieData.asOfDate}
        </Box>
        <ResponsivePie
          data={pieData.slices}
          theme={{
            fontSize: isNonMobile ? (isCompact ? 12 : 20) : isCompact ? 9 : 12,
            axis: {
              domain: {
                line: {
                  stroke: colors.grey[100],
                },
              },
              legend: {
                text: {
                  fill: colors.grey[100],
                },
              },
              ticks: {
                line: {
                  stroke: colors.grey[100],
                  strokeWidth: 1,
                },
                text: {
                  fill: colors.grey[100],
                },
              },
            },
            legends: {
              text: {
                fill: colors.grey[100],
              },
            },
            tooltip: {
              container: {
                color: colors.primary[500],
              },
            },
          }}
          margin={isCompact
            ? { top: 10, right: 10, bottom: 10, left: 10 }
            : { top: 60, right: 60, bottom: 60, left: 60 }
          }
          innerRadius={0.2}
          padAngle={1}
          cornerRadius={5}
          activeOuterRadiusOffset={8}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          enableArcLinkLabels={!isCompact}
          arcLinkLabel={(d) => d.label}
          arcLinkLabelsSkipAngle={isCompact ? 20 : 10}
          arcLinkLabelsTextColor={colors.grey[100]}
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          enableArcLabels={isCompact}
          arcLabel={(d) => d.label}
          arcLabelsRadiusOffset={0.5}
          arcLabelsSkipAngle={45}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          legends={isCompact || true
            ? []
            : [{
              anchor: "top-left",
              direction: "column",
              justify: false,
              translateX: -90,
              translateY: 0,
              itemsSpacing: 15,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#fff",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 12,
              symbolShape: "circle"
            },
          ]}
        />
      </Box>
    );
  }
};

export default PieChart;