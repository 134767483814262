import { Box, Typography, Checkbox, FormControlLabel, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import Header from "../layout/Header";
import clientInfoApi from '../../api/ClientInfo';

const PrivacyAndTerms = (props) => {
    const [privacyAgreed, setPrivacy] = useState(false);
    const [termsAgreed, setTerms] = useState(false);
    const [done, setDone] = useState(false);

    const handlePrivacy = () => {
        setPrivacy(!privacyAgreed);
    }
    const handleTerms = () => {
        setTerms(!termsAgreed);
    }

    const handleSubmit =  async () => {
        await clientInfoApi.updateUserConsent(privacyAgreed, termsAgreed);
        props.setStep(props.currentStep + 1);
    }

    useEffect(() => {
        (async () => {
            const consentInfo = await clientInfoApi.getUserConsent();
            setPrivacy(consentInfo.hasAgreedToPrivacyPolicy);
            setTerms(consentInfo.hasAgreedToTermsOfService);
            setDone(true);
          })();
    }, []);

    return (!done) ? (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
            <Header title="One second..." subtitle="" />
        </Box>
    ) : (
        <Box m="20" sx={{
            display: "flex",
            flexDirection: "column",
        }}>
            <div>
                To continue, please agree to our 
                &nbsp;<a style={{color: "#4cceac"}} href="https://magnoliaassociates.io/downloads/privacypolicy.pdf" target="_blank">Privacy Policy</a>&nbsp;
                and 
                &nbsp;<a style={{color: "#4cceac"}} href="https://magnoliaassociates.io/downloads/termsofservice.pdf" target="_blank">Terms of Service</a>.
            </div>
            <FormControlLabel
                control={
                  <Checkbox
                    checked={privacyAgreed}
                    color="secondary"
                    name="hasAgreedToPrivacyPolicy"
                    onChange={handlePrivacy}
                  />
                }
                label="I agree to the Privacy Policy *"
                sx={{ marginTop: "20px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsAgreed}
                    color="secondary"
                    name="hasAgreedToTermsOfService"
                    onChange={handleTerms}
                  />
                }
                label="I agree to the Terms of Service *"
              />
            <Box sx={{marginTop:"20px", display:"flex", justifyContent:"space-between"}}>
                <Button onClick={() => props.setStep(props.currentStep - 1)} color="secondary" variant="contained">Back</Button>
                <Button onClick={handleSubmit} color="secondary" variant="contained" disabled={!privacyAgreed || !termsAgreed}>Continue</Button>
            </Box>
        </Box>
    );
}

export default PrivacyAndTerms;