import { UserContext } from "../../auth/Verified";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import Header from "../../components/layout/Header";
import { tokens } from "../../theme";
import { useContext } from "react";

const Documents = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const user = useContext(UserContext);
    
    return (
        <Box m="20px">
            <Header title="Document Sharing" subtitle="Use the link below to access your secure SharePoint and share documents with us" />
            <Box display="flex" alignItems="center" justifyContent="center" height="100px">
                <Button 
                    sx={{
                        display: "block",
                        backgroundColor: colors.greenAccent[500],
                        color: colors.grey[100],
                        fontSize: "18px",
                        padding: "5px 10px"
                    }}
                    href={user.sharePointLink} 
                    target="_blank">{user.firstName}'s SharePoint</Button>
            </Box>
        </Box>
    );
}

export default Documents;