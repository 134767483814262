import { Box, Typography, Checkbox, FormControlLabel, Button } from '@mui/material';

const DocumentSharing = (props) => {
    const handleSubmit =  async () => {
        props.setStep(props.currentStep + 1);
    }

    return (
        <Box m="20" sx={{
            display: "flex",
            flexDirection: "column",
        }}>
            <div>
                <p>To continue, please upload the following documents to your secure SharePoint folder found here: </p>
                {props.userSharePoint 
                    ? <p><a style={{color: '#4cceac'}} href={props.userSharePoint} target="_blank">{props.userFirstName}'s SharePoint</a></p>
                    : <p style={{color: 'red', fontWeight: 'bold'}}>SharePoint Link Not Found. Please contact your accountant to set up your SharePoint.</p>
                }
            </div>
            <FormControlLabel
                control={
                    <Checkbox
                    color="secondary"
                    name="hasUploadedBusinessTaxReturns"
                    />
                }
                label="Last 2 years of your businesses' tax returns"
                sx={{ marginTop: "20px" }}
                />
                <FormControlLabel
                control={
                    <Checkbox
                    color="secondary"
                    name="hasUploadedPersonalTaxReturns"
                    />
                }
                label="Last 2 years of your personal tax returns (if you plan on us filing your personal tax returns this year)"
                />
                <FormControlLabel
                control={
                    <Checkbox
                    color="secondary"
                    name="hasUploadedArticlesOfOrganization"
                    />
                }
                label="Articles of Organization/Incorporation (found on the secretary of state website)"
                />
                <FormControlLabel
                control={
                    <Checkbox
                    color="secondary"
                    name="hasUploadedOperatingAgreement"
                    />
                }
                label="LLC Operating Agreement or governing documentation of the business (if applicable)"
                />
                <FormControlLabel
                control={
                    <Checkbox
                    color="secondary"
                    name="hasUploadedPartnershipAgreement"
                    />
                }
                label="Partnership Agreement showing ownership shares in business (if applicable)"
                />
            <Box sx={{marginTop:"20px", display:"flex", justifyContent:"space-between"}}>
                <Button onClick={() => props.setStep(props.currentStep - 1)} color="secondary" variant="contained">Back</Button>
                <Button onClick={handleSubmit} color="secondary" variant="contained">Continue</Button>
            </Box>
        </Box>
    );
}

export default DocumentSharing;