import { useEffect, useState } from 'react';
import { Box, Button, Typography, TextField, useTheme, Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Header from "../layout/Header";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import clientInfoApi from '../../api/ClientInfo';

const CopartnerForm = (props) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleFormSubmit = async (values) => {
        values.zip = values.sameAddressAsTaxpayer ? null : values.zip;
        await clientInfoApi.updateCopartner(values);
        props.doneEditing();
    };

    const schema = GetValidationSchema();

    return (
    <Box m="20px">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{
          id: props.copartner?.id || null,
          firstName: props.copartner?.firstName || null,
          lastName: props.copartner?.lastName || null,
          ssn: props.copartner?.ssn || null,
          dob: props.copartner?.dob || null,
          hasDriversLicenseFrontImage: props.copartner?.hasDriversLicenseFrontImage || false,
          hasDriversLicenseBackImage: props.copartner?.hasDriversLicenseBackImage || false,
          driversLicenseFrontImage: props.copartner?.driversLicenseFrontImage || null,
          driversLicenseBackImage: props.copartner?.driversLicenseBackImage || null,
          sameAddressAsTaxpayer: props.dependent?.sameAddressAsTaxpayer || false,
          address1: props.copartner?.address1 || null,
          address2: props.copartner?.address2 || null,
          city: props.copartner?.city || null,
          state: props.copartner?.state || null,
          zip: props.copartner?.zip || null,
        }}
        validationSchema={schema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Box sx={{ gridColumn: "span 4" }}>
                <Typography 
                  variant="h3" 
                  color={colors.grey[100]} 
                  fontWeight="bold" 
                  sx={{ mb: "5px" }}
                >
                  Copartner Information
                </Typography>
              </Box>
              <input type="hidden" name="id" value={values?.id} />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Social Security Number *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.ssn}
                name="ssn"
                error={!!touched.ssn && !!errors.ssn}
                helperText={touched.ssn && errors.ssn}
                sx={{ gridColumn: "span 2" }}
              />
              <DatePicker
                value={values?.dob} 
                name="dob"
                label="Date of Birth *"
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2" }}
                error={!!touched.dob && !!errors.dob}
                helperText={touched.dob && errors.dob}
                onBlur={handleBlur}
                onChange={value => {
                  const date = new Date(value).toLocaleDateString();
                  setFieldValue('dob', date, true);
                }}
              />
              <input type="hidden" name="hasDriversLicenseFrontImage" value={values.hasDriversLicenseFrontImage} />
              <input type="hidden" name="hasDriversLicenseBackImage" value={values.hasDriversLicenseBackImage} />
              { (values.hasDriversLicenseFrontImage && values.hasDriversLicenseBackImage) ? null : (
                <>
                  <FormControl fullWidth 
                    error={!!touched.driversLicenseFrontImage && !!errors.driversLicenseFrontImage}
                    helperText={touched.driversLicenseFrontImage && errors.driversLicenseFrontImage}
                    sx={{ gridColumn: "span 2" }} 
                    variant="filled" 
                    label="Driver's License Front *">
                    <Typography variant="body2" sx={{ mb: "5px" }}>
                      Driver's License Front *
                    </Typography>
                    <input id="driversLicenseFrontImage" name="driversLicenseFrontImage" type="file" accept="image/*"
                      onChange={(event) => {
                        setFieldValue("driversLicenseFrontImage", event.currentTarget.files[0]);
                      }}
                      onBlur={handleBlur}
                    />
                    <Box>{(errors.driversLicenseFrontImage) ? <p style={{color: 'red', marginBottom: 0}}>{errors.driversLicenseFrontImage}</p> : null}</Box>
                  </FormControl>
                  <FormControl fullWidth 
                    error={!!touched.driversLicenseBackImage && !!errors.driversLicenseBackImage}
                    helperText={touched.driversLicenseBackImage && errors.driversLicenseBackImage}
                    sx={{ gridColumn: "span 2" }} 
                    variant="filled" 
                    label="Driver's License Back *">
                    <Typography variant="body2" sx={{ mb: "5px" }}>
                      Driver's License Back *
                    </Typography>
                    <input id="driversLicenseBackImage" name="driversLicenseBackImage" type="file" accept="image/*"
                      onChange={(event) => {
                          setFieldValue("driversLicenseBackImage", event.currentTarget.files[0]);
                      }}
                      onBlur={handleBlur}
                    />
                    <Box>{(errors.driversLicenseBackImage) ? <p style={{color: 'red', marginBottom: 0}}>{errors.driversLicenseBackImage}</p> : null}</Box>
                  </FormControl>
                </>
              )}
              <FormControl 
                error={!!touched.sameAddressAsTaxpayer && !!errors.sameAddressAsTaxpayer}
                sx={{ gridColumn: "span 4" }}
              >
                <FormControlLabel
                  label="Copartner address is the same as taxpayer address"
                  id="sameAddressAsTaxpayer"
                  control={
                    <Checkbox
                      checked={values?.sameAddressAsTaxpayer}
                      color="secondary"
                      name="sameAddressAsTaxpayer"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  }
                />
              </FormControl>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address 1 *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.address1}
                name="address1"
                error={!!touched.address1 && !!errors.address1}
                helperText={touched.address1 && errors.address1}
                sx={{ gridColumn: "span 2", visibility: values?.sameAddressAsTaxpayer ? 'hidden' : 'visible' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address 2"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.address2}
                name="address2"
                error={!!touched.address2 && !!errors.address2}
                helperText={touched.address2 && errors.address2}
                sx={{ gridColumn: "span 2", visibility: values?.sameAddressAsTaxpayer ? 'hidden' : 'visible' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="City *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.city}
                name="city"
                error={!!touched.city && !!errors.city}
                helperText={touched.city && errors.city}
                sx={{ gridColumn: "span 2", visibility: values?.sameAddressAsTaxpayer ? 'hidden' : 'visible' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="State *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.state}
                name="state"
                error={!!touched.state && !!errors.state}
                helperText={touched.state && errors.state}
                sx={{ gridColumn: "span 2", visibility: values?.sameAddressAsTaxpayer ? 'hidden' : 'visible' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Zip Code *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.zip}
                name="zip"
                error={!!touched.zip && !!errors.zip}
                helperText={touched.zip && errors.zip}
                sx={{ gridColumn: "span 4", visibility: values?.sameAddressAsTaxpayer ? 'hidden' : 'visible' }}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" mt="20px">
                <Button onClick={() => {props.doneEditing()}} color="secondary" variant="contained">
                    Back
                </Button>
                <Button type="submit" color="secondary" variant="contained">
                    Continue
                </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
    )        
}

const dateRegExp = /^(0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])[/-](19|20)\d\d$/;
const ssnRegExp = /^\d{3}-?\d{2}-?\d{4}$/;

const GetValidationSchema = () => {
    return yup.object().shape({
      sameAddressAsTaxpayer: yup.boolean(),
      firstName: yup.string().required("Required"),
      lastName: yup.string().required("Required"),
      ssn: yup.string()
        .matches(ssnRegExp, "SSN should be in the format XXX-XX-XXXX")
        .required("Required"),
      dob: yup.string()
        .required("Required"),
      driversLicenseFrontImage: yup.mixed().when('hasDriversLicenseFrontImage', {
        is: false,
        then: () => yup.mixed().required("Required"),
        otherwise: () => yup.mixed().notRequired()
      }),
      driversLicenseBackImage: yup.mixed().when('hasDriversLicenseBackImage', {
        is: false,
        then: () => yup.mixed().required("Required"),
        otherwise: () => yup.mixed().notRequired()
      }),
      address1: yup.string().when('sameAddressAsTaxpayer', {
        is: false,
        then: () => yup.string().required("Required"),
        otherwise: () => yup.string().nullable()
      }),
      city: yup.string().when('sameAddressAsTaxpayer', {
        is: false,
        then: () => yup.string().required("Required"),
        otherwise: () => yup.string().nullable()
      }),
      state: yup.string().when('sameAddressAsTaxpayer', {
        is: false,
        then: () => yup.string().required("Required"),
        otherwise: () => yup.string().nullable()
      }),
      zip: yup.string().when('sameAddressAsTaxpayer', {
        is: false,
        then: () => yup.string().required("Required").min(5, 'Zip code must be 5 digits').max(5, 'Zip code must be 5 digits'),
        otherwise: () => yup.string().nullable()
      })
    });
  }

export default CopartnerForm;