import {useState, useContext } from "react";
import { Sidebar as ProSidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
// import 'react-pro-sidebar/dist/css/styles.css';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import useClaims from '../../auth/claims';
import { UserContext } from "../../auth/Verified";

const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem active={selected === title} 
            style={{ color: colors.grey[100]}} 
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography></Typography>
            <Link to={to} />
        </MenuItem>
    )
}


const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { collapseSidebar, collapsed } = useProSidebar();
    const [selected, setSelected] = useState('Dashboard');
    const user = useContext(UserContext);

    const { data: claims, isLoading } = useClaims();
    let logoutUrl = claims?.find(claim => claim.type === 'bff:logout_url').value;
    let nameDict = claims?.find(claim => claim.type === 'name');
    let gravatarDict = claims?.find(claim => claim.type === 'picture');
    let username = nameDict?.value;
    let gravatarUri = gravatarDict?.value;

    return (
      <Box
        sx={{
          "& .ps-sidebar-root": {
            border: "none !important"
          },
          "& .ps-sidebar-container": {
            background: `${colors.primary[400]} !important`,
          },
          "& .ps-menu-button:hover": {
            backgroundColor: "rgb(20, 27, 45) !important",
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            padding: "5px 35px 5px 20px !important",
          },
          "& .pro-inner-item:hover": {
            color: "#868dfb !important",
          },
          "& .ps-menu-root": {
            paddingTop: "15px",
          },
          "& .ps-menuitem-root.ps-active": {
            color: "#6870fa !important",
          },
        }}
      >
      <ProSidebar>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          {/* <MenuItem
            onClick={() => collapseSidebar}
            icon={collapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  
                </Typography>
                <IconButton onClick={() => collapseSidebar}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem> */}

          {!collapsed && username && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={gravatarUri}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {user.firstName} {user.lastName}
                </Typography>
                {/* <Typography variant="h5" color={colors.greenAccent[500]}>
                  Owner / CPA
                </Typography> */}
              </Box>
            </Box>
          )}

          <Box paddingLeft={collapsed ? undefined : "10%"}>
            <MenuItem
              title="Dashboard"
              icon={<HomeOutlinedIcon />}
              component={<Link to="/" />}
            >Dashboard</MenuItem>

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Collaboration
            </Typography>
            <MenuItem
              title="Document Sharing"
              icon={<BarChartOutlinedIcon />}
              component={<Link to="/documents" />}
            >Document Sharing</MenuItem>

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography>
            <MenuItem
              title="Sales Data"
              icon={<BarChartOutlinedIcon />}
              component={<Link to="/bar" />}
            >Sales Data</MenuItem>
            <MenuItem
              title="Expenses"
              icon={<PieChartOutlineOutlinedIcon />}
              component={<Link to="/pie" />}
            >Expenses</MenuItem>
            <MenuItem
              title="Yearly Review"
              icon={<TimelineOutlinedIcon />}
              component={<Link to="/line" />}
            >Yearly Review</MenuItem>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
    )
}

export default Sidebar;