import { useEffect, useState } from 'react';
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Box, Button, useTheme} from "@mui/material";
import Header from "../layout/Header";
import { tokens } from "../../theme";
import clientInfoApi from '../../api/ClientInfo';
import DependentForm from './DependentForm';
import AddIcon from '@mui/icons-material/Add';
import YesNoQuestion from './YesNoQuestion';

const DependentsList = (props) => {

    const [dependents, setDependents] = useState([]);
    const [selectedDependent, setSelectedDependent] = useState(null);
    const [hasDependent, setHasDependent] = useState(false);
    const [done, setDone] = useState(false);
    const columns = [
        { field: "id", headerName: "ID" },
        { field: "firstName", headerName: "First Name" },
        { field: "lastName", headerName: "Last Name" },
        { field: "relationToTaxpayer", headerName: "Relationship", flex: 1 },
        { field: "dob", headerName: "Birth Date", type: "date" },
        { field: "sameAddressAsTaxpayer", headerName: "Same Address As Taxpayer" },
        { field: "address1", headerName: "Address 1" },
        { field: "address2", headerName: "Address 2" },
        { field: "city", headerName: "City" },
        { field: "state", headerName: "State" },
        { field: "zipCode", headerName: "Zip Code" },
    ];

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        (async () => {
            setDependents(await clientInfoApi.getDependents());
            setDone(true);
        })();
    }, []);

    const handleDoneEditing = () => {
        setSelectedDependent(null);
        setDependents([]);
        setDone(false);
        (async () => {
            setDependents(await clientInfoApi.getDependents());
            setDone(true);
        })();
    }

    return !done ?
    (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
            <Header title="One second..." subtitle="" />
        </Box>
    ) : (!dependents.length && !hasDependent) ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" height="100%">
            <YesNoQuestion 
                question="Do you have any dependents?" 
                handleYes={() => {setHasDependent(true)}} 
                handleNo={() => {props.setStep(props.currentStep + 1)}}
                handleBack={() => {props.setStep(props.currentStep - 1)}} />
        </Box>
    ) : (!selectedDependent) ? (
        <Box
            m="40px 0 0 0"
            sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400]
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700]
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
            }}
        >
            <Box display="flex" justifyContent="space-between">
                <Header title="Dependents" subtitle="Click on a dependent to edit their information." />
                <div>
                    <Button 
                        color="secondary" 
                        variant="contained"
                        onClick={() => {setSelectedDependent({})}} 
                    >
                        <AddIcon fontSize="medium" /> Add
                    </Button>
                </div>
            </Box>
            <DataGrid
                rows={dependents}
                columns={columns}
                columnVisibilityModel={{
                    id: false,
                    dob: false,
                    sameAddressAsTaxpayer: false,
                    address1: false,
                    address2: false,
                    city: false,
                    state: false,
                    zipCode: false,
                }}
                onRowClick={params => { setSelectedDependent(params.row) }}
                autoHeight
                hideFooter
                sx={{
                    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                        outline: "none",
                    },
                    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
                        outline: "none",
                    }
                }}
            />
            <Box display="flex" justifyContent="space-between" mt="20px">
                <Button onClick={() => {props.setStep(props.currentStep - 1)}} color="secondary" variant="contained">
                    Back
                </Button>
                <Button onClick={() => {props.setStep(props.currentStep + 1)}} color="secondary" variant="contained">
                    Continue
                </Button>
            </Box>
        </Box>
    ) : (
        <Box m="40px 0 0 0">
            <DependentForm dependent={selectedDependent} doneEditing={handleDoneEditing} />
        </Box>
    );
}

export default DependentsList;