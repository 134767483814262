import { QueryClientProvider, QueryClient } from 'react-query';
import { ColorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ProSidebarProvider} from "react-pro-sidebar";
import { Routes, Route } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar"
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Contacts from "./scenes/contacts";
import Invoices from "./scenes/invoices";
import Form from "./scenes/form";
import Calendar from "./scenes/calendar";
import FAQ from "./scenes/faq";
import Bar from "./scenes/bar";
import Pie from "./scenes/pie";
import Line from "./scenes/line";
import Geography from "./scenes/geography";
import Documents from "./scenes/documents";
import Authenticated from './auth/Authenticated';
import Verified from './auth/Verified';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function App() {
  const [theme, colorMode] = useMode();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  return (
    <QueryClientProvider client={new QueryClient()}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Authenticated>
              <Verified>
                <div className="app">
                  {isNonMobile
                    ? (<ProSidebarProvider>
                        <Sidebar />
                      </ProSidebarProvider>)
                    : undefined}
                  <main className="content">
                    <Topbar />
                    <Routes>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/documents" element={<Documents />} />
                      <Route path="/team" element={<Team />} />
                      <Route path="/contacts" element={<Contacts />} />
                      <Route path="/invoices" element={<Invoices />} />
                      <Route path="/form" element={<Form />} />
                      <Route path="/calendar" element={<Calendar />} />
                      <Route path="/faq" element={<FAQ />} />
                      <Route path="/bar" element={<Bar />} />
                      <Route path="/pie" element={<Pie />} />
                      <Route path="/line" element={<Line />} />
                      <Route path="/geo" element={<Geography />} />
                    </Routes>
                  </main>
                </div>
              </Verified>
            </Authenticated>
          </LocalizationProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </QueryClientProvider>
  )
}

export default App;
