import { useState } from "react";
import ClientInfoForm from "./ClientInfoForm";
import SpouseForm from "./SpouseForm";
import DependentsList from "./DependentsList";
import DocumentSharing from "./DocumentSharing";
import BusinessInfoForm from "./BusinessInfoForm";
import EndOnboardingForm from "./EndOnboardingForm";
import PrivacyAndTerms from "./PrivacyAndTerms";
import OnboardingInstructions from "./OnboardingInstructions";
import CopartnerList from "./CopartnerList";
import YesNoQuestion from "./YesNoQuestion";

const OnboardingJourney = (props) => {
  const [step, setStep] = useState(0);

  const renderSwitch = () => {
    switch (step) {
      case 0:
        return (
          <OnboardingInstructions currentStep={step} setStep={setStep}></OnboardingInstructions>
        )
      case 1:
        return (
          <PrivacyAndTerms currentStep={step} setStep={setStep}></PrivacyAndTerms>
        )
      case 2:
        return (
          <ClientInfoForm currentStep={step} setStep={setStep}></ClientInfoForm>
        );
      case 3:
        return (
          <YesNoQuestion 
            question={"Do you plan on Magnolia Associates preparing your personal taxes?"}
            handleBack={() => setStep(2)}
            handleYes={() => setStep(4)} 
            handleNo={() => setStep(6)} />
        );
      case 4:
        return (
          <SpouseForm currentStep={step} setStep={setStep}></SpouseForm>
        );
      case 5:
        return (
          <DependentsList currentStep={step} setStep={setStep}></DependentsList>
        );
      case 6:
        return (
          <BusinessInfoForm currentStep={step} setStep={setStep}></BusinessInfoForm>
        );
      case 7:
        return (
          <CopartnerList currentStep={step} setStep={setStep}></CopartnerList>
        );
      case 8:
        return (
          <DocumentSharing currentStep={step} setStep={setStep} userFirstName={props.userFirstName} userSharePoint={props.userSharePoint}></DocumentSharing>
        )
      case 9:
        return (
          <EndOnboardingForm currentStep={step} setStep={setStep}></EndOnboardingForm>
        )
      default:
        console.error('Unknown step');
        throw new Error('Unknown step');
    };
  }

  return (
    <>
      {renderSwitch()}
    </>
  );
};

export default OnboardingJourney;