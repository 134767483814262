import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import useClaims from '../../auth/claims';

const Topbar = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    const { data: claims, isLoading } = useClaims();
    let logoutUrl = claims?.find(claim => claim.type === 'bff:logout_url').value;
    let nameDict = claims?.find(claim => claim.type === 'name');
    let username = nameDict?.value;

    let loginUrl = (process.env.NODE_ENV == 'production')
    ? '/bff/login?returnUrl=/'
    : 'https://localhost:5002/bff/login?returnUrl=/';

    const handleLoginButtonClick = () => {
        window.location.href = loginUrl;
    }
    const handleLogoutButtonClick = () => {
        window.location.href = logoutUrl;
    }
    const handleHomeClick = () => {
        navigate('/');
    }

    return <Box display="flex" justifyContent="space-between" p={2}>
        {/* SEARCH BAR */}
        <Box>
            <Typography variant="h1" onclick={handleHomeClick} sx={{fontSize: "20px", margin: "0", cursor: "pointer"}}>
                Magnolia Associates
            </Typography>
        </Box>

        {/* ICONS & Authentication */}
        {
            isLoading ? (
                <p>Loading...</p>
            ) : (
                <Box display="flex">
                    <IconButton onClick={colorMode.toggleColorMode}>
                        {theme.palette.mode === 'dark' ? (
                            <DarkModeOutlinedIcon />
                        ) : (
                            <LightModeOutlinedIcon />
                        )}
                    </IconButton>
                    {/* <IconButton>
                        <NotificationsOutlinedIcon />
                    </IconButton>
                    <IconButton>
                        <SettingsOutlinedIcon />
                    </IconButton> */}
                    {/* <IconButton onClick={handleLoginButton}>
                        <PersonOutlinedIcon />
                    </IconButton> */}
                    {
                        !username ? (
                            <Button
                                onClick={handleLoginButtonClick}
                                ml="5px"
                                sx={{
                                    display: "block",
                                    backgroundColor: colors.greenAccent[500],
                                    color: colors.grey[100],
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    padding: "5px 10px"
                                }}
                                >
                                LOGIN
                            </Button>
                        ) : (
                            <Button
                                onClick={handleLogoutButtonClick}
                                ml="5px"
                                sx={{
                                    display: "block",
                                    backgroundColor: colors.greenAccent[500],
                                    color: colors.grey[100],
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    padding: "5px 10px"
                                }}
                                >
                                LOGOUT
                            </Button>
                        )
                    }
                </Box>
            )
        }
    </Box>;
};

export default Topbar;