import dayjs from 'dayjs';
import { magassGet, magassPost } from './apiCalls';

export default {

    getUserConsent: async function () {
        const magassResponse = await magassGet('api/Client/UserConsent');
        return magassResponse.data;
    },

    updateUserConsent: async function (privacyAgreed, termsAgreed) {
        const magassResponse = await magassPost('api/Client/UserConsent/Update', {hasAgreedToPrivacyPolicy: privacyAgreed, hasAgreedToTermsOfService: termsAgreed});
        return magassResponse.success;
    },

    getUserContext: async function () {
        const magassResponse = await magassGet('api/Client/UserContext');
        return magassResponse.data;
    },

    //Client
    getClient: async function () {
        const magassResponse = await magassGet('api/Client');
        const clientInfo = magassResponse.data;
        return {
            firstName: clientInfo?.firstName,
            lastName: clientInfo?.lastName,
            email: clientInfo?.email,
            phone: clientInfo?.phone,
            altPhone: clientInfo?.altPhone,
            ssn: clientInfo?.ssn,
            dob: this.convertStringToDayJs(clientInfo?.dob),
            filingStatus: clientInfo?.filingStatus,
            hasDriversLicenseFrontImage: clientInfo?.hasDriversLicenseFrontImage ?? false,
            hasDriversLicenseBackImage: clientInfo?.hasDriversLicenseBackImage ?? false,
            driversLicenseFrontImage: clientInfo?.driversLicenseFrontImage,
            driversLicenseBackImage: clientInfo?.driversLicenseBackImage,
            address1: clientInfo?.address1,
            address2: clientInfo?.address2,
            city: clientInfo?.city,
            state: clientInfo?.state,
            zip: clientInfo?.zip
        };
    },

    updateClient: async function (clientInfo) {
        let headers = {
            'x-csrf': 1,
            'content-type': 'multipart/form-data'
        }
        let clientFormData = new FormData();
        for (let key in clientInfo) {
            clientFormData.append(key, clientInfo[key] ?? '');
        }

        const magassResponse = await magassPost('api/Client/Update', clientFormData, headers);
        return magassResponse.success;
    },

    //Spouse
    getSpouse: async function () {
        const magassResponse = await magassGet('api/Client/Spouse');
        const spouseInfo = magassResponse.data;
        return {
            id: spouseInfo?.id,
            firstName: spouseInfo?.firstName,
            lastName: spouseInfo?.lastName,
            ssn: spouseInfo?.ssn,
            dob: this.convertStringToDayJs(spouseInfo?.dob),
            hasDriversLicenseFrontImage: spouseInfo?.hasDriversLicenseFrontImage ?? false,
            hasDriversLicenseBackImage: spouseInfo?.hasDriversLicenseBackImage ?? false,
            driversLicenseFrontImage: spouseInfo?.driversLicenseFrontImage,
            driversLicenseBackImage: spouseInfo?.driversLicenseBackImage,
            sameAddressAsTaxpayer: spouseInfo?.sameAddressAsTaxpayer ?? false,
            address1: spouseInfo?.address1,
            address2: spouseInfo?.address2,
            city: spouseInfo?.city,
            state: spouseInfo?.state,
            zip: spouseInfo?.zip
        };
    },

    updateSpouse: async function (spouseInfo) {
        let headers = {
            'x-csrf': 1,
            'content-type': 'multipart/form-data'
        }
        let spouseFormData = new FormData();
        for (let key in spouseInfo) {
            spouseFormData.append(key, spouseInfo[key] ?? '');
        }

        const magassResponse = await magassPost('api/Client/Spouse/Update', spouseFormData, headers);
        return magassResponse.success;
    },

    //Dependents
    getDependents: async function () {
        const magassResponse = await magassGet('api/Client/Dependents');
        const dependents = magassResponse.data;
        if (dependents) {
            dependents.forEach(dependent => {
                dependent.dob = this.convertStringToDayJs(dependent.dob);
            });
        }
        return dependents;
    },

    updateDependent: async function (dependentInfo) {
        const magassResponse = await magassPost('api/Client/Dependent/Update', dependentInfo);
        return magassResponse.success;
    },

    //Copartners
    getCopartners: async function () {
        const magassResponse = await magassGet('api/Client/Copartners');
        const copartners = magassResponse.data;
        if (copartners) {
            copartners.forEach(copartner => {
                copartner.dob = this.convertStringToDayJs(copartner.dob);
            });
        }
        return copartners;
    },

    updateCopartner: async function (copartnerInfo) {
        let headers = {
            'x-csrf': 1,
            'content-type': 'multipart/form-data'
        }
        let copartnerFormData = new FormData();
        for (let key in copartnerInfo) {
            copartnerFormData.append(key, copartnerInfo[key] ?? '');
        }

        const magassResponse = await magassPost('api/Client/Copartner/Update', copartnerFormData, headers);
        return magassResponse.success;
    },

    //Businesses
    getBusinesses: async function () {
        const magassResponse = await magassGet('api/Client/Businesses');
        const businesses = magassResponse.data;
        if (businesses) {
            businesses.forEach(business => {
                business.entityReportDueDate = this.convertStringToDayJs(business.entityReportDueDate);
                business.dateOfIncorporation = this.convertStringToDayJs(business.dateOfIncorporation);
            });
        }
        return businesses;
    },

    updateBusiness: async function (businessInfo) {
        const magassResponse = await magassPost('api/Client/Business/Update', businessInfo);
        return magassResponse.success;
    },

    convertStringToDayJs: function (date) {
        return (date)
            ? dayjs(new Date(date).toLocaleDateString())
            : dayjs(null);
    },

    handleException: function (error, message) {
        console.log(error);
        alert(message);
    },
}