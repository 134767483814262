import { useEffect, useState } from 'react';
import { Box, Button, Typography, useTheme} from "@mui/material";
import { tokens } from "../../theme";
import axios from "axios";

const EndOnboardingForm = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [doneVerifying, setDoneVerifying] = useState(false);
    const [isError, setIsError] = useState(false);

    let apiBaseUrl = (process.env.NODE_ENV == 'production')
        ? ''
        : 'https://localhost:5002';

    useEffect(() => {
        const headers = {
            'x-csrf': 1
        };
        axios.post(`${apiBaseUrl}/api/Client/Verify`, '', { headers })
        .then(response => {
            if (response.data.success) {
                setDoneVerifying(true);
            }
            else {
                setIsError(true);
                console.error('Error verifying user: ', response.data.message);
            }
        })
        .catch(error => {
            setIsError(true);
            console.error('Error verifying user: ', error);
        });
    }, []);

    const handleButtonClick = () => {
        window.location.reload();
    }

    return (doneVerifying && !isError) ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" height="100%" >
            <Typography variant="h1" sx={{margin: "5px 0"}}>You're all set!</Typography>
            <Typography variant="h3" sx={{margin: "5px 0"}}>Thank you for choosing Magnolia Associates</Typography>
            <Box display="flex" justifyContent="space-between" width="100%" mt="20px">
                <Button onClick={() => {props.nextStep(4)}} color="secondary" variant="contained">
                    Back
                </Button>
                <Button onClick={handleButtonClick} color="secondary" variant="contained" sx={{margin: "5px 0"}}>Continue to Dashboard</Button>
            </Box>
        </Box>
    ) : isError ? (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%" >
            <Typography variant="h1">Uh oh. There was a problem.</Typography>
            <Typography variant="h3">Please let your accountant know this happened, and we'll fix it right away.</Typography>
        </Box>
    ) : (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%" >
            <Typography variant="h1">One second...</Typography>
        </Box>
    );
}

export default EndOnboardingForm;