import axios from 'axios';
import logging from '../logging/logging';

let baseUrl = (process.env.NODE_ENV === 'production')
    ? ''
    : 'https://localhost:5002';

let headers = {
    'x-csrf': 1,
    'content-type': 'application/json',
    'accept': 'application/json'
};

//Expects api to return a MagassResponse object
export const magassGet = async (endpoint, customHeaders = headers, errorCallback = logging.handleException) => {
    const url = `${baseUrl}${endpoint}`;
    const response = await axios.get(url, { headers: customHeaders })
        .catch(error => {
            errorCallback(error);
        });
    if (!response.data.success) {
        errorCallback(response.data.message);
    }
    return response.data;
}

//Expects api to return a MagassResponse object
export const magassPost = async (endpoint, data, customHeaders = headers, errorCallback = logging.handleException) => {
    const url = `${baseUrl}${endpoint}`;
    const magassResponse = await axios.post(url, data, { headers: customHeaders })
        .catch(error => {
            errorCallback(error);
        });
    if (!magassResponse.data.success) {
        errorCallback(magassResponse.data.message);
    }
    return magassResponse.data;
}

export const get = async (endpoint, customHeaders = headers, errorCallback = logging.handleException) => {
    const url = `${baseUrl}${endpoint}`;
    const response = await axios.get(url, { headers: customHeaders })
        .catch(error => {
            errorCallback(error);
        });
    return response.data;
}