import { Box, Button, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Header from "../components/layout/Header";
import useClaims from "./claims";

const Authenticated = (props) => {
    const { data: claims, isLoading } = useClaims();
    let nameDict = claims?.find(claim => claim.type === 'name');
    let username = nameDict?.value;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    let loginUrl = (process.env.NODE_ENV == 'production')
      ? '/bff/login?returnUrl=/'
      : 'https://localhost:5002/bff/login?returnUrl=/';
    const handleLoginButtonClick = () => {
      window.location.href = loginUrl;
    }

    return isLoading ? (
      <Box m="20px" display="flex" height="100%" width="100%" justifyContent="center" alignItems="center">
        <Header title="One second..." subtitle="" />
      </Box>
    ) : !username ? (
      <Box display="flex" flexDirection="column" height="90%" width="100%" justifyContent="center" alignItems="center">
        <Header mt="-50px" title="MAGNOLIA ASSOCIATES" subtitle="Login to continue" />
        <Button
          onClick={handleLoginButtonClick}
          sx={{
            display: "block",
            backgroundColor: colors.greenAccent[500],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            margin: "10px 0"
          }}
        >
          LOGIN
        </Button>
      </Box>
    ) : (
      <>
        {props.children}
      </>
    )
}

export { Authenticated as default }