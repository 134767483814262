import { useEffect, useState, createContext } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Header from "../components/layout/Header";
import OnboardingJourney from "../components/forms/OnboardingJourney";
import clientInfoApi from "../api/ClientInfo";
import useClaims from './claims';

export const UserContext = createContext();

const Verified = (props) => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data: claims, isLoading } = useClaims();
    let logoutUrl = claims?.find(claim => claim.type === 'bff:logout_url').value;
    
    const [userInfo, setUserInfo] = useState('Loading...');

    useEffect(() => {
        (async () => {
            const user = await clientInfoApi.getUserContext();
            setUserInfo(user);
        })();
    }, []);

    const handleLogoutButtonClick = () => {
        window.location.href = logoutUrl;
    }

    return (userInfo === 'Loading...') ? (
        <Box display="flex" flexDirection="column" height="100%" width="100%" justifyContent="center" alignItems="center">
            <Box>
                <Header title="One second..." subtitle="" />
            </Box>
            <Button
                onClick={handleLogoutButtonClick}
                color="secondary" variant="contained"
                sx={{margin: "20px 0 0 0", display: "block"}}
            >
                LOGOUT
            </Button>
        </Box>
    ) : (userInfo === undefined) ? (
        <Box padding="30px" display="flex" flexDirection="column" width="100%" justifyContent="center" alignItems="center">
            <Box>
                <Header title="Uh oh. There was a problem." subtitle="Please let your accountant know this happened, and we'll fix it right away." />
            </Box>
            <Button
                onClick={handleLogoutButtonClick}
                color="secondary" variant="contained"
                sx={{margin: "0 0 20px 0", display: "block"}}
            >
                LOGOUT
            </Button>
        </Box>
    ) : (!userInfo.isVerified) ? (
        <Box padding="30px" display="flex" width="100%" justifyContent="center" alignItems="center">
            <Box>
                <Box sx={{width: "100%", display: "flex", justifyContent: "right"}}>
                    <Button
                        onClick={handleLogoutButtonClick}
                        color="secondary" variant="contained"
                        sx={{margin: "0 0 20px 0"}}
                    >
                        LOGOUT
                    </Button>
                </Box>
                <Header title="Welcome to Magnolia Associates" subtitle="Let's get your information" />
                <OnboardingJourney userFirstName={userInfo.firstName} userSharePoint={userInfo.sharePointLink}></OnboardingJourney>
            </Box>
        </Box>
    ) : (
        <>
            <UserContext.Provider value={userInfo}>
                {props.children}
            </UserContext.Provider>
        </>
    )
}

export { Verified as default }