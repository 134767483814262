import { useState, useEffect } from 'react';
import { Box, Button, Typography, TextField, useTheme, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import clientInfoApi from '../../api/ClientInfo';

const ClientInfoForm = (props) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [done, setDone] = useState(false);
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
      (async () => {
        const clientInfo = await clientInfoApi.getClient();
        setDone(true);
        setInitialValues(clientInfo);
      })();
    }, []);
    
    const handleFormSubmit = async (values) => {
        setDone(false);
        await clientInfoApi.updateClient(values);
        props.setStep(props.currentStep + 1);
    };

    const schema = GetValidationSchema();

    return !done ?
    (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
            One second...
        </Box>
    ) : (
    <Box m="20px">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={schema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Box sx={{ gridColumn: "span 4" }}>
                <Typography 
                  variant="h3" 
                  color={colors.grey[100]} 
                  fontWeight="bold" 
                  sx={{ mb: "5px" }}
                >
                  Client Information
                </Typography>
              </Box>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                name="phone"
                error={!!touched.phone && !!errors.phone}
                helperText={touched.phone && errors.phone}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Alternate Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.altPhone}
                name="altPhone"
                error={!!touched.altPhone && !!errors.altPhone}
                helperText={touched.altPhone && errors.altPhone}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Social Security Number *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ssn}
                name="ssn"
                error={!!touched.ssn && !!errors.ssn}
                helperText={touched.ssn && errors.ssn}
                sx={{ gridColumn: "span 2" }}
              />
              <DatePicker
                value={values.dob} 
                name="dob"
                label="Date of Birth *"
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2" }}
                error={!!touched.dob && !!errors.dob}
                helperText={touched.dob && errors.dob}
                onBlur={handleBlur}
                onChange={value => {
                  const date = new Date(value).toLocaleDateString();
                  setFieldValue('dob', date, true);
                }}
              />
              <FormControl fullWidth sx={{ gridColumn: "span 4" }}>
                <InputLabel id="filing-status-label">Tax Filing Status</InputLabel>
                <Select
                  labelId="filing-status-label"
                  variant="filled"
                  name="filingStatus"
                  value={values.filingStatus}
                  label="Tax Filing Status"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.filingStatus && !!errors.filingStatus}
                  helperText={touched.filingStatus && errors.filingStatus}
                >
                  <MenuItem value={0}>Single</MenuItem>
                  <MenuItem value={1}>Married Filing Jointly</MenuItem>
                  <MenuItem value={2}>Married Filing Separately</MenuItem>
                  <MenuItem value={3}>Head of Household</MenuItem>
                </Select>
              </FormControl>
              <input type="hidden" name="hasDriversLicenseFrontImage" value={values.hasDriversLicenseFrontImage} />
              <input type="hidden" name="hasDriversLicenseBackImage" value={values.hasDriversLicenseBackImage} />
              { (values.hasDriversLicenseFrontImage && values.hasDriversLicenseBackImage) ? null : (
                <>
                  <FormControl fullWidth sx={{ gridColumn: "span 2" }} variant="filled" label="Driver's License Front *">
                    <Typography variant="body2" sx={{ mb: "5px" }}>
                      Driver's License Front *
                    </Typography>
                    <input id="driversLicenseFrontImage" name="driversLicenseFrontImage" type="file" accept="image/*"
                      onChange={(event) => {
                        setFieldValue("driversLicenseFrontImage", event.currentTarget.files[0]);
                      }}
                      onBlur={handleBlur}
                      error={!!touched.driversLicenseFrontImage && !!errors.driversLicenseFrontImage}
                      helperText={touched.driversLicenseFrontImage && errors.driversLicenseFrontImage}
                    />
                    <Box>{(errors.driversLicenseFrontImage) ? <p style={{color: 'red', marginBottom: 0}}>{errors.driversLicenseFrontImage}</p> : null}</Box>
                  </FormControl>
                    <FormControl fullWidth sx={{ gridColumn: "span 2" }} variant="filled" label="Driver's License Back *">
                      <Typography variant="body2" sx={{ mb: "5px" }}>
                        Driver's License Back *
                      </Typography>
                      <input id="driversLicenseBackImage" name="driversLicenseBackImage" type="file" accept="image/*"
                        onChange={(event) => {
                            setFieldValue("driversLicenseBackImage", event.currentTarget.files[0]);
                        }}
                        onBlur={handleBlur}
                        error={!!touched.driversLicenseBackImage && !!errors.driversLicenseBackImage}
                        helperText={touched.driversLicenseBackImage && errors.driversLicenseBackImage}
                      />
                      <Box>{(errors.driversLicenseBackImage) ? <p style={{color: 'red', marginBottom: 0}}>{errors.driversLicenseBackImage}</p> : null}</Box>
                  </FormControl>
                </>
              )}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address 1 *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address1}
                name="address1"
                error={!!touched.address1 && !!errors.address1}
                helperText={touched.address1 && errors.address1}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address 2"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address2}
                name="address2"
                error={!!touched.address2 && !!errors.address2}
                helperText={touched.address2 && errors.address2}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="City *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.city}
                name="city"
                error={!!touched.city && !!errors.city}
                helperText={touched.city && errors.city}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="State *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.state}
                name="state"
                error={!!touched.state && !!errors.state}
                helperText={touched.state && errors.state}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Zip Code *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.zip}
                name="zip"
                error={!!touched.zip && !!errors.zip}
                helperText={touched.zip && errors.zip}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" mt="20px">
                <Button onClick={() => {props.setStep(props.currentStep - 1)}} color="secondary" variant="contained">
                    Back
                </Button>
                <Button type="submit" color="secondary" variant="contained">
                    Continue
                </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
    )        
}

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const dateRegExp = /^(0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])[/-](19|20)\d\d$/;
const ssnRegExp = /^\d{3}-?\d{2}-?\d{4}$/;

const GetValidationSchema = () => {
    return yup.object().shape({
      firstName: yup.string().required("Required"),
      lastName: yup.string().required("Required"),
      email: yup.string().email("invalid email").required("Required"),
      phone: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Required"),
      ssn: yup.string()
        .matches(ssnRegExp, "SSN should be in the format XXX-XX-XXXX")
        .required("Required"),
      dob: yup.string().required("Required"),
      driversLicenseFrontImage: yup.mixed().when('hasDriversLicenseFrontImage', {
        is: false,
        then: () => yup.mixed().required("Required"),
        otherwise: () => yup.mixed().notRequired()
      }),
      driversLicenseBackImage: yup.mixed().when('hasDriversLicenseBackImage', {
        is: false,
        then: () => yup.mixed().required("Required"),
        otherwise: () => yup.mixed().notRequired()
      }),
      address1: yup.string().required("Required"),
      city: yup.string().required("Required"),
      state: yup.string().required("Required"),
      zip: yup.string().required("Required").min(5, 'Zip code must be 5 digits').max(5, 'Zip code must be 5 digits'),
    });
  }

export default ClientInfoForm;