import { Box } from "@mui/material";
import Header from "../../components/layout/Header";
import LineChart from "../../components/charts/LineChart";

const Line = () => {
  return (
    <Box m="30px">
      <Header title="Financials Detail" subtitle="" />
      <Box height="75vh">
        <LineChart resourceUrl='/api/charts/line-chart?metrics=profit,revenue,expenses' isDashboard={false} />
      </Box>
    </Box>
  );
};

export default Line; 