import { Box } from "@mui/material";
import Header from "../../components/layout/Header";
import PieChart from "../../components/charts/PieChart";

const Pie = () => {
  return (
    <Box m="20px">
      <Header title="Expense Detail" subtitle="" />
      <Box height="75vh">
        <PieChart resourceUrl='/api/charts/pie-chart?metric=expenses' isCompact={false} />
      </Box>
    </Box>
  );
};

export default Pie;