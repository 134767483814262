import React from 'react';
import { Box, Button } from "@mui/material";
import Header from '../layout/Header';

const YesNoQuestion = ({question, handleYes, handleNo, handleBack}) => {
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" height="100%">
            <Header title={question} subtitle="" />
            <Box display="flex" justifyContent="center" width="100%">
                <Button onClick={() => {handleYes()}} color="secondary" variant="contained" sx={{margin: "0 10px"}}>
                    Yes
                </Button>
                <Button onClick={() => {handleNo()}} color="secondary" variant="contained" sx={{margin: "0 10px"}}>
                    No
                </Button>
            </Box>
            {handleBack && (
                <Box display="flex" justifyContent="center" width="100%" sx={{marginTop: "10px"}}>
                    <Button onClick={() => {handleBack()}} color="secondary" variant="contained" sx={{margin: "0 10px"}}>
                        Back
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default YesNoQuestion;
