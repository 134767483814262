import { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockLineData as data } from "../../data/mockData";
import axios from "axios";

const LineChart = ({ resourceUrl, isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let apiBaseUrl = (process.env.NODE_ENV == 'production')
    ? ''
    : 'https://localhost:5002';

  const [lineData, setLineData] = useState('Loading...');

  useEffect(() => {
    const headers = {
      'x-csrf': 1
    };
    axios.get(`${apiBaseUrl}${resourceUrl}`, { headers })
    .then(response => {
      setLineData(!response.data.data ? undefined : response.data);
      console.log(lineData);
    })
    .catch(error => {
      setLineData(undefined);
      console.error('Error fetching data:', error);
    });
  }, []);

  if (lineData === 'Loading...') {
    return (
      <Box height="250px" m="20px 0 0 0" p="0 30px">
        Loading...
      </Box>
    )
  }
  else if (!lineData) {
    return (
      <Box height="250px" m="20px 0 0 0" p="0 30px">
        No Data
      </Box>
    )
  }
  else {
    return (
      <Box height="100%" widht="100%">
        <ResponsiveLine
          data={lineData.data.lines}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: colors.grey[100],
                },
              },
              legend: {
                text: {
                  fill: colors.grey[100],
                },
              },
              ticks: {
                line: {
                  stroke: colors.grey[100],
                  strokeWidth: 1,
                },
                text: {
                  fill: colors.grey[100],
                },
              },
            },
            legends: {
              text: {
                fill: colors.grey[100],
              },
            },
            tooltip: {
              container: {
                color: colors.primary[500],
              },
            },
          }}
          colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} // added
          margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: lineData.data.lowerBound,
            max: lineData.data.upperBound,
            stacked: false,
            reverse: false,
          }}
          yFormat=" >-.2f"
          curve="catmullRom"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legend: isDashboard ? undefined : "Month", // added
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            orient: "left",
            tickValues: 5, // added
            tickSize: 3,
            tickPadding: 5,
            tickRotation: 0,
            legend: isDashboard ? undefined : "Amount", // added
            legendOffset: -40,
            legendPosition: "middle",
          }}
          enableGridX={false}
          enableGridY={false}
          pointSize={8}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </Box>
    );
  }
};

export default LineChart;