import { Box, Button, ListItemIcon } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Header from "../layout/Header";

const OnboardingInstructions = (props) => {
    const handleSubmit = () => {
        props.setStep(props.currentStep + 1);
    }

    return (
        <Box m="20px" sx={{display:"flex", flexDirection:"column"}}>
            <Box sx={{display:"flex", flexDirection:"column", maxWidth:"450px", fontSize:"16px"}}>
                <p>
                    To get started, please note that the following list of information will be required. If you plan on having us file your personal tax returns, the information below could be required from your spouse and dependents as well.
                </p>
                <p>
                    Your progress will be saved every time you hit the continue button.
                </p>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <DoneIcon />
                        </ListItemIcon>
                        SSN and DOB
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <DoneIcon />
                        </ListItemIcon>
                        Drivers License images  (front and back)
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <DoneIcon />
                        </ListItemIcon>
                        Your business' EIN (found on the form SS-4 filed with the IRS)
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <DoneIcon />
                        </ListItemIcon>
                        Your business' Date of Organization/Incorporation (found on the secretary of state website)
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <DoneIcon />
                        </ListItemIcon>
                        Your business' Articles of Organization/Incorporation (found on the secretary of state website)
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <DoneIcon />
                        </ListItemIcon>
                        Your business' Operating Agreement or governing documentation of the business (if applicable)
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <DoneIcon />
                        </ListItemIcon>
                        Your business' Partnership Agreement showing ownership shares in business (if applicable)
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <DoneIcon />
                        </ListItemIcon>
                        Last 2 years of your businesses' tax returns
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <DoneIcon />
                        </ListItemIcon>
                        Last 2 years of your personal tax returns (if applicable)
                    </ListItem>
                </List>
                <p>
                    The estimated amount of time to fill out this form is less than 15 minutes.
                </p>
            </Box>
            <Box sx={{marginTop:"20px", display:"flex", justifyContent:"space-between"}}>
                <div></div>
                <Button variant="contained" color="secondary" onClick={() => props.setStep(props.currentStep + 1)} sx={{ marginTop: "20px" }}>
                    Continue
                </Button>
            </Box>
        </Box>
    );
}

export default OnboardingInstructions;